import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function AccountVerification({ t, isAxiosReady, setIsLoading, setLoadingText }) {
    const { token } = useParams();

    const [isTokenOk, setIsTokenOk] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setLoadingText(t("Vérification de votre compte..."));

        if (isAxiosReady) {
            axios.get(`/user/verify?token=${token}`).then(async (response) => {
                console.log(response.data, response);
                setIsTokenOk(true);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [isAxiosReady, token]);

    return (
        <div id="accountVerification">
            {
                isTokenOk ? (
                    <h1 className='success'>{t("Adresse email vérifiée avec succès !")}</h1>
                ) : (
                    <h1 className='failed'>{t("Aucun compte trouvé !")}</h1>
                )
            }
        </div>
    )
}

export default AccountVerification
