import React from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import SubscriptionPlans from '../components/SubscriptionPlans';
import FrenchBooksNotation from '../components/FrenchBooksNotation';
import WorldBooksNotation from '../components/WorldBooksNotation';

function Pricing({ t, setIsLoading, isAxiosReady }) {
    return (
        <div id="pricing">
            <h1>{t("A chaque")} <strong>{t("parieur gagnant")}</strong> {t("son")} <strong>{t("plan idéal")}</strong></h1>

            <SubscriptionPlans t={t} setIsLoading={setIsLoading} isAxiosReady={isAxiosReady} />

            <div className="paymentMethods">
                <img src="./images/paymentMethods/visa.png" alt="visa" />
                <img src="./images/paymentMethods/mastercard.png" alt="mastercard" />
                <img src="./images/paymentMethods/paypal.png" alt="paypal" />
                <img src="./images/paymentMethods/apple_pay.png" alt="apple_pay" />
                <img src="./images/paymentMethods/google_pay.png" alt="google_pay" />
                <img src="./images/paymentMethods/link.png" alt="link" />
                <img src="./images/paymentMethods/union_pay.png" alt="union_pay" />
                <img src="./images/paymentMethods/american_express.png" alt="american_express" />
            </div>

            <div className="bookmakersContainer">
                <p>(1) {t("Liste des bookmakers de l'offre française")}</p>
                <FrenchBooksNotation t={t} />
            </div>

            <div className="bookmakersContainer">
                <p>(2) {t("Liste des bookmakers de l'offre internationale")}</p>
                <WorldBooksNotation t={t} />
            </div>
        </div>
    )
}

export default Pricing