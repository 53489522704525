import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import FormField from "../../components/FormField";
import StatusPill from "../../components/StatusPill";
import Popup from "../../components/Popup";
import { Tooltip } from "react-tooltip";
import { IoIosNotifications, IoIosNotificationsOff } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { GoCopilot } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function AdministrationUsers({ t, isAxiosReady, setIsLoading, setLoadingText, login }) {
	const navigate = useNavigate();

	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [filterFrance, setFilterFrance] = useState("all");
	const [filterWorld, setFilterWorld] = useState("all");
	const [filterGrade, setFilterGrade] = useState("all");
	const [searchTerm, setSearchTerm] = useState("");
	const [pushTokens, setPushTokens] = useState([]);
	const [showNotificationPopup, setShowNotificationPopup] = useState(false);
	const [notificationTitle, setNotificationTitle] = useState("");
	const [notificationBody, setNotificationBody] = useState("");
	const [notificationData, setNotificationData] = useState("dashboard");

	useEffect(() => {
		if (!isAxiosReady) {
			setIsLoading(true);
			setLoadingText(t("Chargement des utilisateurs..."));
		} else {
			const fetchUsers = async () => {
				try {
					const response = await axios.get("/admin/users");
					setUsers(response.data);
					setFilteredUsers(response.data);
				} catch (err) {
					console.error(err);
				} finally {
					setIsLoading(false);
				}
			};
			fetchUsers();
		}
	}, [isAxiosReady, setIsLoading, setLoadingText, t]);

	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric" };
		const userLanguage = navigator.language || "fr-FR";
		return `${t("Le")} ${new Date(dateString).toLocaleDateString(userLanguage, options)}`;
	};

	const columns = [
		{ name: t("Email"), selector: (row) => row.email, sortable: true },
		{ name: t("Nom d'utilisateur"), selector: (row) => row.username, sortable: true },
		{ name: t("Grade"), selector: (row) => (row.grade === "administrator" ? t("Administrateur") : t("Membre")), sortable: true },
		{ name: t("Abonnement France"), cell: (row) => <StatusPill status={row.stripeFranceSubscriptionState} />, sortable: true },
		{ name: t("Abonnement Monde"), cell: (row) => <StatusPill status={row.stripeWorldSubscriptionState} />, sortable: true },
		{ name: t("Membre depuis"), selector: (row) => formatDate(row.createdAt), sortable: true },
		{
			name: t("Actions"),
			cell: (row) => (
				<div className="datatable-actions">
					<button className="btn thematized" onClick={() => handleAction("manageUser", row)}>
						<FaUserEdit id="tooltip_user" />
					</button>
					<button className="btn thematized" onClick={() => handleAction("controlAccount", row)}>
						<GoCopilot id="tooltip_pilot" />
					</button>
					<button className="btn thematized" onClick={() => handleAction("pushNotification", row)} disabled={!row.pushToken}>
						{row.pushToken ? <IoIosNotifications id="tooltip_push_notifications" /> : <IoIosNotificationsOff id="tooltip_push_disabled_notifications" />}
					</button>
				</div>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
			width: "150px",
		},
	];

	useEffect(() => {
		let filtered = users;
		if (filterFrance !== "all") {
			filtered = filtered.filter((user) => user.stripeFranceSubscriptionState === filterFrance);
		}
		if (filterWorld !== "all") {
			filtered = filtered.filter((user) => user.stripeWorldSubscriptionState === filterWorld);
		}
		if (filterGrade !== "all") {
			filtered = filtered.filter((user) => user.grade === filterGrade);
		}
		if (searchTerm) {
			filtered = filtered.filter((user) => user.email.toLowerCase().includes(searchTerm.toLowerCase()) || user.username.toLowerCase().includes(searchTerm.toLowerCase()));
		}
		setFilteredUsers(filtered);
	}, [filterFrance, filterWorld, filterGrade, searchTerm, users]);

	const handleSearchChange = (value) => setSearchTerm(value);

	const handleSelectedRowsChange = ({ selectedRows }) => setSelectedUsers(selectedRows);

	const closeNotificationPopup = () => {
		setShowNotificationPopup(false);
		setPushTokens([]);
		setNotificationTitle("");
		setNotificationBody("");
		setNotificationData("dashboard");
	};

	const handleAction = (actionType, user = null) => {
		switch (actionType) {
			case "pushNotification":
				const tokens = user ? [{ email: user.email, pushToken: user.pushToken }] : selectedUsers.map((user) => ({ email: user.email, pushToken: user.pushToken }));
				setPushTokens(tokens);
				setShowNotificationPopup(true);
				break;
			case "manageUser":
				break;
			case "controlAccount":
				controlAccount(user.email);
				break;
			default:
				break;
		}
	};

	const sendNotification = async () => {
		const validTokens = pushTokens.filter((token) => token.pushToken);
		const messages = validTokens.map((token) => ({
			to: token.pushToken,
			sound: "default",
			title: notificationTitle,
			body: notificationBody,
			data: { view: notificationData },
		}));

		if (messages.length === 0) {
			closeNotificationPopup();
			return;
		}

		try {
			const response = await axios.post("/push-notifications/send", { messages });
			if (response.status === 200) {
				alert(`${t("Notification envoyée à")}: ${validTokens.map((pt) => pt.email).join(", ")}`);
			} else {
				alert(t("Échec de l'envoi des notifications."));
			}
		} catch (error) {
			console.error(error);
			alert(t("Erreur lors de l'envoi des notifications."));
		} finally {
			closeNotificationPopup();
		}
	};

	const controlAccount = async (email) => {
		try {
			const result = await axios.post("/admin/controlAccount", { email });
			login(result.data.token, result.data.user);
			navigate("/", { replace: true });
		} catch (err) {
			console.error(err);
			setIsLoading(false);
		}
	};

	const subscriptionOptions = [
		{ value: "all", label: t("Tous") },
		{ value: "active", label: t("Actif") },
		{ value: "inactive", label: t("Inactif") },
		{ value: "deleted", label: t("Supprimé") },
		{ value: "paused", label: t("En pause") },
		{ value: "unpaid", label: t("Non payé") },
		{ value: "trialing", label: t("Période d'essai") },
	];

	const gradeOptions = [
		{ value: "all", label: t("Tous") },
		{ value: "administrator", label: t("Administrateur") },
		{ value: "member", label: t("Membre") },
	];

	const notificationDataOptions = [
		{ value: "dashboard", label: t("Accueil") },
		{ value: "profile", label: t("Profil") },
		{ value: "parameters", label: t("Paramètres") },
		{ value: "notifications", label: t("Notifications") },
	];

	return (
		<div id="adminUsers">
			<Tooltip anchorSelect="#tooltip_push_notifications">{t("Notification Push")}</Tooltip>
			<Tooltip anchorSelect="#tooltip_push_disabled_notifications">{t("Notification Push non disponible")}</Tooltip>
			<Tooltip anchorSelect="#tooltip_user">{t("Gérer le Compte")}</Tooltip>
			<Tooltip anchorSelect="#tooltip_pilot">{t("Piloter le Compte")}</Tooltip>

			<h1>{t("Administration des utilisateurs")}</h1>
			<div className="filters">
				<FormField type="select" id="filterFrance" label={t("Filtrer par abonnement France")} value={filterFrance} onChange={setFilterFrance} options={subscriptionOptions} />
				<FormField type="select" id="filterWorld" label={t("Filtrer par abonnement Monde")} value={filterWorld} onChange={setFilterWorld} options={subscriptionOptions} />
				<FormField type="select" id="filterGrade" label={t("Filtrer par grade")} value={filterGrade} onChange={setFilterGrade} options={gradeOptions} />
				<FormField type="text" id="searchTerm" label={t("Recherche")} value={searchTerm} onChange={handleSearchChange} />
			</div>
			{selectedUsers.length > 0 && (
				<div className="action-bar">
					<button className="btn thematized" onClick={() => handleAction("pushNotification")}>
						<IoIosNotifications />
						<span>{t("Notification Push")}</span>
					</button>
				</div>
			)}

			<DataTable columns={columns} data={filteredUsers} defaultSortFieldId={3} selectableRows onSelectedRowsChange={handleSelectedRowsChange} pagination paginationPerPage={20} />
			<Popup t={t} title={t("Envoyer une notification push")} isOpened={showNotificationPopup} setIsOpened={closeNotificationPopup} confirmAction={sendNotification}>
				<form>
					<FormField type="text" id="notificationTitle" label={t("Titre de la notification")} value={notificationTitle} onChange={setNotificationTitle} />
					<FormField type="textarea" id="notificationBody" label={t("Message de la notification")} value={notificationBody} onChange={setNotificationBody} />
					<FormField type="select" id="notificationData" label={t("Vue à afficher")} value={notificationData} onChange={setNotificationData} options={notificationDataOptions} />
				</form>
				{pushTokens.length > 0 && (
					<div className="info">
						<p>
							{t("Nombre de notifications push qui seront envoyées :")} {pushTokens.filter((token) => token.pushToken).length} {t("sur")} {pushTokens.length}
						</p>
						{pushTokens.some((token) => !token.pushToken) && (
							<p className="warning">
								{t("Les utilisateurs suivants ne recevront pas de notification car ils n'ont pas de token push :")}
								<br />
								{pushTokens
									.filter((token) => !token.pushToken)
									.map((token) => token.email)
									.join(", ")}
							</p>
						)}
					</div>
				)}
			</Popup>
		</div>
	);
}

export default AdministrationUsers;
