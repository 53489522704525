import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import toast from "react-hot-toast";
import { BiCopy } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import FormField from '../components/FormField';
import { AiFillEdit, AiFillDelete, AiFillStar } from 'react-icons/ai';
import { FaArrowCircleLeft, FaArrowCircleRight, FaPlus } from "react-icons/fa";
import { IoMdAddCircle, IoIosArrowForward } from 'react-icons/io';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import Popup from '../components/Popup';
import { Tooltip } from "react-tooltip";
import { stylizedSportsName, stylizedBookName, allMarkets, getPrintableDuration } from "../utils";
import { useNavigate } from "react-router-dom";

const subscriptionsParameters = {
    "BSM-FRANCE": {
        "name": "Abonnement français",
        "botName": "BetSet&Match - France",
        "botShortName": "BSM-FRANCE",
        "TGBotURL": "https://t.me/FreeMoneyOddsBot"
    },
    "BSM-WORLD": {
        "name": "Abonnement international",
        "botName": "BetSet&Match - World",
        "botShortName": "BSM-WORLD",
        "TGBotURL": "https://t.me/BSMInternationalBot"
    }
}

const getMarketRealName = (key) => {
    let foundMarketValue;
    allMarkets.forEach(sportMarkets => {
        sportMarkets.options.forEach(market => {
            if (market.value === key) {
                foundMarketValue = market.label;
            }
        });
    });

    return foundMarketValue;
}

function PresetsManagement({ t, isAxiosReady, setIsLoading, setLoadingText }) {
    const [presets, setPresets] = useState({});

    useEffect(() => {
        setIsLoading(true);
        setLoadingText(t("Chargement de vos configurations..."));
    }, []);

    useEffect(() => {
        if (isAxiosReady) {
            axios.get("/robot/all").then(response => {
                setPresets(response.data);
                setIsLoading(false);
            });
        }
    }, [isAxiosReady]);

    const handleUpdatePreset = (subscriptionName, newPresets) => {
        const tempPresets = { ...presets };
        tempPresets[subscriptionName] = newPresets;

        setPresets(tempPresets);
    }

    return (
        <div id="presetsManagement">
            <h1>{t("Gestion de vos presets")}</h1>

            <p className="alert">
                <BsFillInfoCircleFill />
                {t("Vos presets sont considérés de gauche à droite par le robot BSM. Si votre premier preset satisfait les conditions d'un pari alors il ne regardera pas les presets suivants ! Pensez donc à les mettre dans le bon ordre ou simplement à ne pas avoir des conditions qui se chevauchent d'un preset à un autre.")}
            </p>

            <div id="subscriptionsContainer">
                {isAxiosReady && <SubscriptionPresets t={t} subscriptionName="BSM-FRANCE" presets={presets["BSM-FRANCE"]} setPresets={handleUpdatePreset} allPresets={presets} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}
                {isAxiosReady && <SubscriptionPresets t={t} subscriptionName="BSM-WORLD" presets={presets["BSM-WORLD"]} setPresets={handleUpdatePreset} allPresets={presets} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}
            </div>
        </div >
    )
}

export default PresetsManagement;

function SubscriptionPresets({ t, subscriptionName, presets, setPresets, allPresets, setIsLoading, setLoadingText }) {
    const subscriptionParameters = subscriptionsParameters[subscriptionName];
    const [completeView, setCompleteView] = useState(false);

    const handleSetPreset = (newPresets) => {
        setPresets(subscriptionName, newPresets);
    }

    const changePriority = (presetIndex, direction) => {
        setIsLoading(true);
        setLoadingText(t("Sauvegarde de vos presets..."));

        let newPresets = [...presets];

        if (presetIndex === presets.length - 2 && direction === 1)
            return;
        if (presetIndex === 0 && direction === -1)
            return;

        newPresets[presetIndex + direction].priority = newPresets[presetIndex + direction].priority + direction;
        newPresets[presetIndex].priority = newPresets[presetIndex].priority - direction;
        handleSetPreset(newPresets);

        const firstPackage = { "id": newPresets[presetIndex + direction].id, "priority": newPresets[presetIndex + direction].priority };
        const secondPackage = { "id": newPresets[presetIndex].id, "priority": newPresets[presetIndex].priority };

        axios.put("/robot", firstPackage).then(response => {
            axios.put("/robot", secondPackage).then(response2 => {
                toast.success(t("La modification de priorité a bien été faite !"));
                setIsLoading(false);
            }).catch(err => {
                toast.error(t("La modification de priorité n'a pas pu être faite !"));
                setIsLoading(false);
            });
        }).catch(err => {
            toast.error(t("La modification de priorité n'a pas pu être faite !"));
            setIsLoading(false);
        });
    }

    const deletePreset = (preset, doneFunction) => {
        setIsLoading(true);
        setLoadingText(t("Sauvegarde de vos presets..."));

        axios.delete("/robot/" + preset.id).then(async response => {
            const presetsToUpdate = [];
            let tempPresets = presets.filter(p => p.id !== preset.id);
            tempPresets = tempPresets.map(p => {
                if (p.priority > preset.priority) {
                    p.priority--;
                    presetsToUpdate.push(p);
                }
                return p;
            });

            const presetsUpdatedPromises = presetsToUpdate.map(async currentPreset => axios.put("/robot", currentPreset));
            await Promise.all(presetsUpdatedPromises);

            setPresets(subscriptionName, tempPresets);
            doneFunction();

            toast.success(t("Le preset a bien été supprimé !"));
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            toast.error(t("Le preset n'a pas pu être supprimé !"));
            setIsLoading(false);
        });
    }

    return (
        <div className="subscriptionPresets">
            <h2 className="subscriptionName" onClick={() => setCompleteView(!completeView)}>
                {subscriptionParameters.botName}
                {presets && presets.length > 0 && <FaPlus className={completeView ? "rotated" : ""} />}
            </h2>

            {
                presets && presets.length > 0 ?
                    completeView ?
                        <div className="presets">
                            {presets.sort((a, b) => b.priority - a.priority).map((preset, index) => <Preset t={t} key={preset.id + "-" + index} preset={preset} presets={presets} setPresets={handleSetPreset} changePriority={(direction) => changePriority(index, direction)} deletePreset={deletePreset} />)}
                            <NewPreset t={t} presets={presets} setPresets={handleSetPreset} allPresets={allPresets} subscriptionParameters={subscriptionParameters} />
                        </div>
                        :
                        <div className="presets reduced">
                            {presets.sort((a, b) => b.priority - a.priority).map((preset, index) => <ReducedViewPreset t={t} key={preset.id + "-" + index} preset={preset} presets={presets} setPresets={handleSetPreset} deletePreset={deletePreset} />)}
                        </div>
                    :
                    <NoRobotDetected t={t} subscriptionParameters={subscriptionParameters} />
            }
        </div >
    )
}

function Preset({ t, preset, presets, setPresets, changePriority, deletePreset }) {
    const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);

    const handleDeletePreset = () => {
        setIsDeletePopupOpened(true);
    }

    const handleConfirmDeletePreset = () => {
        deletePreset(preset, () => setIsDeletePopupOpened(false));
    }

    const handleChangePriority = (direction) => changePriority(direction);

    return (
        <>
            <Tooltip anchorSelect="#tooltip_moveLeft">{t("Déplacer le preset à gauche")}</Tooltip>
            <Tooltip anchorSelect="#tooltip_moveRight">{t("Déplacer le preset à droite")}</Tooltip>
            <Tooltip anchorSelect="#tooltip_delete">{t("Supprimer le preset")}</Tooltip>

            <div className="preset">
                <h3 className="title">
                    {!preset.isDefaultPreset && (
                        <div className="changePriority">
                            {preset.priority !== presets.length && <FaArrowCircleLeft id="tooltip_moveLeft" onClick={() => handleChangePriority(-1)} />}
                            {preset.priority !== 2 && <FaArrowCircleRight id="tooltip_moveRight" onClick={() => handleChangePriority(1)} />}
                        </div>
                    )}
                    {preset.isDefaultPreset && <AiFillStar className="byDefault" />}
                    {preset.title ? preset.title : t("Sans titre")}
                    {!preset.isDefaultPreset && <AiFillDelete className="remove" id="tooltip_delete" onClick={handleDeletePreset} />}
                </h3>
                <p className="description">{preset.description ? preset.description : <i>{t("Aucune description")}</i>}</p>

                <h4 className="subTitle">{t("Conditions de sélection")}</h4>
                {!("isDefaultPreset" in preset) || ("isDefaultPreset" in preset && preset.isDefaultPreset === true) ?
                    <p>{t("Aucune condition n'est applicable pour le preset par défaut !")}</p>
                    : Object.keys(preset).some(p => (p.startsWith("isConditionActive_") && preset[p] === true) || (["condition_sports", "condition_markets", "condition_books"].includes(p) && preset[p] && preset[p].length > 0)) ? (
                        <ul className="conditions">
                            {preset.condition_sports && preset.condition_sports.split(", ").length > 0 && (
                                <li>
                                    <strong>{t("Sport(s)")} : </strong>
                                    {preset.condition_sports.split(", ").map(a => stylizedSportsName[a]).join(", ")}
                                </li>
                            )}
                            {preset.condition_markets && preset.condition_markets.split(", ").length > 0 && (
                                <li>
                                    <strong>{t("Marché(s)")} : </strong>
                                    {preset.condition_markets.split(", ").map(marketKey => getMarketRealName(marketKey)).join(", ")}
                                </li>
                            )}
                            {preset.condition_books && preset.condition_books.split(", ").length > 0 && (
                                <li>
                                    <strong>{t("Bookmaker(s)")} : </strong>
                                    {preset.condition_books.split(", ").map(a => stylizedBookName[a]).join(", ")}
                                </li>
                            )}
                            {
                                preset.isConditionActive_minTRJ && !preset.isConditionActive_maxTRJ ?
                                    <li><strong>{t("TRJ")}</strong> ≥ {preset.condition_minTRJ.toString().replace(".", ",")}%</li>
                                    : preset.isConditionActive_minTRJ && preset.isConditionActive_maxTRJ ?
                                        <li>{preset.condition_minTRJ.toString().replace(".", ",")}% ≤ <strong>{t("TRJ")}</strong> ≤ {preset.condition_maxTRJ.toString().replace(".", ",")}%</li>
                                        : !preset.isConditionActive_minTRJ && preset.isConditionActive_maxTRJ ?
                                            <li><strong>{t("TRJ")}</strong> ≤ {preset.condition_maxTRJ.toString().replace(".", ",")}%</li>
                                            : null
                            }
                            {
                                preset.isConditionActive_minEV && !preset.isConditionActive_maxEV ?
                                    <li><strong>{t("EV")}</strong> ≥ {preset.condition_minEV.toString().replace(".", ",")}%</li>
                                    : preset.isConditionActive_minEV && preset.isConditionActive_maxEV ?
                                        <li>{preset.condition_minEV.toString().replace(".", ",")}% ≤ <strong>{t("EV")}</strong> ≤ {preset.condition_maxEV.toString().replace(".", ",")}%</li>
                                        : !preset.isConditionActive_minEV && preset.isConditionActive_maxEV ?
                                            <li><strong>{t("EV")}</strong> ≤ {preset.condition_maxEV.toString().replace(".", ",")}%</li>
                                            : null
                            }
                            {
                                preset.isConditionActive_minTRJPlusEV && !preset.isConditionActive_maxTRJPlusEV ?
                                    <li><strong>{t("TRJ + EV")}</strong> ≥ {preset.condition_minTRJPlusEV.toString().replace(".", ",")}%</li>
                                    : preset.isConditionActive_minTRJPlusEV && preset.isConditionActive_maxTRJPlusEV ?
                                        <li>{preset.condition_minTRJPlusEV.toString().replace(".", ",")}% ≤ <strong>{t("TRJ + EV")}</strong> ≤ {preset.condition_maxTRJPlusEV.toString().replace(".", ",")}%</li>
                                        : !preset.isConditionActive_minTRJPlusEV && preset.isConditionActive_maxTRJPlusEV ?
                                            <li><strong>{t("TRJ + EV")}</strong> ≤ {preset.condition_maxTRJPlusEV.toString().replace(".", ",")}%</li>
                                            : null
                            }
                            {
                                preset.isConditionActive_minLiquidity && !preset.isConditionActive_maxLiquidity ?
                                    <li><strong>{t("Liquidité")}</strong> ≥ {preset.condition_minLiquidity.toString().replace(".", ",")}{t("€")}</li>
                                    : preset.isConditionActive_minLiquidity && preset.isConditionActive_maxLiquidity ?
                                        <li>{preset.condition_minLiquidity.toString().replace(".", ",")}{t("€")} ≤ <strong>{t("Liquidité")}</strong> ≤ {preset.condition_maxLiquidity.toString().replace(".", ",")}{t("€")}</li>
                                        : !preset.isConditionActive_minLiquidity && preset.isConditionActive_maxLiquidity ?
                                            <li><strong>{t("Liquidité")}</strong> ≤ {preset.condition_maxLiquidity.toString().replace(".", ",")}{t("€")}</li>
                                            : null
                            }
                            {
                                preset.isConditionActive_minOdd && !preset.isConditionActive_maxOdd ?
                                    <li><strong>{t("Cote")}</strong> ≥ {preset.condition_minOdd.toString().replace(".", ",")}</li>
                                    : preset.isConditionActive_minOdd && preset.isConditionActive_maxOdd ?
                                        <li>{preset.condition_minOdd.toString().replace(".", ",")} ≤ <strong>{t("Cote")}</strong> ≤ {preset.condition_maxOdd.toString().replace(".", ",")}</li>
                                        : !preset.isConditionActive_minOdd && preset.isConditionActive_maxOdd ?
                                            <li><strong>{t("Cote")}</strong> ≤ {preset.condition_maxOdd.toString().replace(".", ",")}</li>
                                            : null
                            }
                            {
                                preset.isConditionActive_minTimeSinceOpening && !preset.isConditionActive_maxTimeSinceOpening ?
                                    <li><strong>{t("Temps depuis l'ouverture du marché")}</strong> ≥ {getPrintableDuration(preset.condition_minTimeSinceOpening)}</li>
                                    : preset.isConditionActive_minTimeSinceOpening && preset.isConditionActive_maxTimeSinceOpening ?
                                        <li>{getPrintableDuration(preset.condition_minTimeSinceOpening)} ≤ <strong>{t("Temps depuis l'ouverture du marché")}</strong> ≤ {getPrintableDuration(preset.condition_maxTimeSinceOpening)}</li>
                                        : !preset.isConditionActive_minTimeSinceOpening && preset.isConditionActive_maxTimeSinceOpening ?
                                            <li><strong>{t("Temps depuis l'ouverture du marché")}</strong> ≤ {getPrintableDuration(preset.condition_maxTimeSinceOpening)}</li>
                                            : null
                            }
                            {
                                preset.isConditionActive_minTimeBeforeClosing && !preset.isConditionActive_maxTimeBeforeClosing ?
                                    <li><strong>{t("Temps avant le début du match")}</strong> ≥ {getPrintableDuration(preset.condition_minTimeBeforeClosing)}</li>
                                    : preset.isConditionActive_minTimeBeforeClosing && preset.isConditionActive_maxTimeBeforeClosing ?
                                        <li>{getPrintableDuration(preset.condition_minTimeBeforeClosing)} ≤ <strong>{t("Temps avant le début du match")}</strong> ≤ {getPrintableDuration(preset.condition_maxTimeBeforeClosing)}</li>
                                        : !preset.isConditionActive_minTimeBeforeClosing && preset.isConditionActive_maxTimeBeforeClosing ?
                                            <li><strong>{t("Temps avant le début du match")}</strong> ≤ {getPrintableDuration(preset.condition_maxTimeBeforeClosing)}</li>
                                            : null
                            }
                        </ul>
                    ) : <p>{t("Aucune condition n'a été sélectionnée.")}</p>
                }
                <Link to={"/preset/" + preset.id}>
                    <button className="btn thematized"><AiFillEdit />{t("Modifier")}</button>
                </Link>
            </div>

            <Popup t={t} title="Supprimer le preset" confirmAction={handleConfirmDeletePreset} isOpened={isDeletePopupOpened} setIsOpened={setIsDeletePopupOpened}>
                <p>{t("Êtes-vous sûr de vouloir supprimer ce preset ? Cette action est irréversible !")}</p>
            </Popup>
        </>
    )
}

function ReducedViewPreset({ t, preset, presets, setPresets, deletePreset }) {
    const navigate = useNavigate();
    const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);

    const handleDeletePreset = () => {
        setIsDeletePopupOpened(true);
    }

    const handleConfirmDeletePreset = () => {
        deletePreset(preset, () => setIsDeletePopupOpened(false));
    }

    const handleClickOnEdit = () => {
        navigate(`/preset/${preset.id}`, { replace: true });
    }

    return (
        <>
            <Tooltip anchorSelect="#tooltip_delete">{t("Supprimer le preset")}</Tooltip>
            <Tooltip anchorSelect="#tooltip_edit">{t("Modifier le preset")}</Tooltip>

            <div className="preset">
                <div className="clickable" onClick={handleClickOnEdit}></div>
                {preset.isDefaultPreset && <AiFillStar className="byDefault" />}
                {preset.title ? preset.title : t("Sans titre")}
                {!preset.isDefaultPreset && <AiFillDelete className="remove" id="tooltip_delete" onClick={handleDeletePreset} />}
            </div>

            <Popup t={t} title="Supprimer le preset" confirmAction={handleConfirmDeletePreset} isOpened={isDeletePopupOpened} setIsOpened={setIsDeletePopupOpened}>
                <p>{t("Êtes-vous sûr de vouloir supprimer ce preset ? Cette action est irréversible !")}</p>
            </Popup>
        </>
    )
}

function NewPreset({ t, presets, setPresets, allPresets, subscriptionParameters }) {
    const [presetModel, setPresetModel] = useState();

    let presetsModelsOptions = [{ value: "-1", label: t("Aucun modèle") }];
    for (const [subscriptionName, subPresets] of Object.entries(allPresets)) {
        presetsModelsOptions = [...presetsModelsOptions, ...subPresets.map(preset => { return { value: preset.id, label: preset.title ? `${subscriptionName} - ${preset.title}` : `${subscriptionName} - ${t("Sans titre")}` } })];
    }

    const handlePresetModelChange = (newIndex) => {
        let foundPreset;
        Object.values(allPresets).forEach(p => {
            const found = p.find(preset => preset.id == newIndex)
            if (found) {
                foundPreset = found;
            }
        })
        setPresetModel(foundPreset);
    }

    const handleCreatePreset = () => {
        const modelToDuplicate = presetModel ? { ...presetModel } : {};

        if (presetModel) {
            delete modelToDuplicate.id;
            modelToDuplicate.betIndications = JSON.parse(presetModel.betIndications);
        }

        modelToDuplicate.channelId = presets[0].channelId;
        modelToDuplicate.subscriptionName = subscriptionParameters.botShortName;
        modelToDuplicate.title = t("Preset ", { "index": presets.length + 1 });
        modelToDuplicate.description = "";
        modelToDuplicate.isDefaultPreset = false;
        modelToDuplicate.priority = presets.length + 1;

        axios.post("/robot", modelToDuplicate)
            .then(response => {
                toast.success(t("Le preset a bien été créé !"));
                setPresets([...presets, response.data]);
            }).catch(err => {
                console.log(err);
                toast.error(t("Le preset n'a pas pu être créé !"));
            });
    }

    return (
        <div className="preset new">
            <h3 className="title">{t("Nouveau preset")}</h3>
            <FormField type="select" id="presetModel" value={presetModel ? presetModel.id : -1} label={t("Modèle")} options={presetsModelsOptions} onChange={handlePresetModelChange} />
            <button className="btn success" onClick={handleCreatePreset}><IoMdAddCircle />{t("Créer le preset")}</button>
        </div>
    )
}

function NoRobotDetected({ t, subscriptionParameters }) {
    const botID = JSON.parse(localStorage.getItem("user")).botId;
    const botCommand = `/identify ${botID} ${subscriptionParameters.botShortName}`;

    const handleCopyIdentify = (e) => {
        navigator.clipboard.writeText(botCommand);
        toast.success(t("Valeur copiée !"));
    }

    return (
        <div id="noRobotContainer">
            <p>{t("Vous n'avez pas encore commencé l'interaction avec votre robot ! Pour ce faire, veuillez suivre la procédure ci-dessous")} :</p>
            <ul>
                <li>{t("Cliquez sur le lien suivant")} : <a href={subscriptionParameters.TGBotURL} target="_blank" rel="noreferrer">{subscriptionParameters.botName}</a> ;</li>
                <li>{t("Sur un téléphone avec l'application Télégram installée : cliquez sur \"Send message\" sinon cliquez sur \"Open in web\"")} ;</li>
                <li>{t("Cliquez sur le bouton \"Démarrer\" en bas de la conversation")} ;</li>
                <li>{t("Copiez la ligne suivante")} :
                    <div className="inputWithCopyButton">
                        <input type="text" value={botCommand} readOnly />
                        <BiCopy size={30} title="Copier" onClick={handleCopyIdentify} />
                    </div>
                </li>
                <li>{t("Retournez sur Télégram et collez la ligne puis envoyez le message")} ;</li>
                <li>{t("Attendez que le robot vous confirme l'ajout")} ;</li>
                <li>{t("Actualisez la page et paramétrez votre robot")} !</li>
            </ul>
        </div>
    )
}