import React from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { ImCross } from "react-icons/im";

function NotationStars({ number }) {
    return (
        <div className="starsContainer">
            {
                number === -1 ? <ImCross className="nothing" /> : (
                    [1, 2, 3, 4, 5].map((current, index) => current <= number ? <FaStar key={index} /> : number % 1 === 0.5 ? <FaStarHalfAlt key={index} /> : <FaRegStar key={index} />)
                )
            }
        </div>
    )
}

export default NotationStars
