import React, { useEffect, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';

function MultipleFormField({ t, type, id, value, label, className = "", afterLabel = [], tooltip = [], placeholder = [], onChange, checked = false, min = [], max = [], disabled = [], setDisabled = [], conditionable = [] }) {
    const [isDisabled, setIsDisabled] = useState(disabled);

    const handleUpdateDisable = (newValue, index) => {
        let newDisabled = [...isDisabled];
        newDisabled[index] = newValue;
        setIsDisabled(newDisabled);
        setDisabled[index](!newValue);
    }

    return (
        <div className={"formField multiple " + className}>
            {
                id.map((curr, index) => (
                    <span key={index}>
                        <label htmlFor={id[index]}>
                            {label[index]}
                            {tooltip[index] ? <BsFillInfoCircleFill className="tooltipTrigger" id={tooltip[index]} /> : null}
                            {label[index].length > 0 ? " : " : ""}
                        </label>
                        {
                            conditionable && conditionable.length >= index && conditionable[index] ?
                                <input type="checkbox" className="conditionableField" checked={!isDisabled[index]} onChange={e => handleUpdateDisable(!isDisabled[index], index)} />
                                : null
                        }
                        {
                            type[index] === "number" ?
                                <input type={type[index]} name={id[index]} id={id[index]} value={value[index]} disabled={isDisabled && isDisabled[index] ? isDisabled[index] : false} min={min ? min[index] : null} max={max ? max[index] : null} placeholder={placeholder[index]} onChange={(e) => onChange[index](e.target.value)} />
                                : (
                                    type[index] === "checkbox" ?
                                        <input type={type[index]} name={id[index]} id={id[index]} onChange={(e) => onChange[index](!checked[index])} checked={checked[index]} disabled={isDisabled[index]} />
                                        : <input type={type[index]} name={id[index]} id={id[index]} value={value[index]} disabled={isDisabled && isDisabled[index] ? isDisabled[index] : false} placeholder={placeholder[index]} onChange={(e) => onChange[index](e.target.value)} />
                                )
                        }

                        {afterLabel && afterLabel.length >= index ? afterLabel[index] : ""}
                    </span>
                ))
            }
        </div >
    )
}

export default MultipleFormField
