import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SubscriptionPlans from '../components/SubscriptionPlans';
import FrenchBooksNotation from '../components/FrenchBooksNotation';
import WorldBooksNotation from '../components/WorldBooksNotation';

function Subscription({ t, isAxiosReady, user, hasActiveSubscription, setIsLoading, setLoadingText }) {
    const handleClickOnProceedSubscription = async (subscriptionID) => {
        setIsLoading(true);
        setLoadingText(t("Chargement de votre paiement..."));

        const results = await axios.post("/subscription/createCheckoutSession", { subscriptionID });
        if (results.data && results.data.url) {
            window.location.href = results.data.url;
        }
        else {
            console.log(results);
        }
    };

    const handleClickOnManageSubscription = async () => {
        setIsLoading(true);
        setLoadingText(t("Chargement de votre abonnement..."));

        const results = await axios.post("/subscription/createPortalSession");
        if (results.data && results.data.url) {
            window.location.href = results.data.url;
        }
        else {
            console.log(results);
        }
    }

    return (
        <div id="subscription">
            <h1>{t("Mon abonnement")}</h1>

            <p className="alert">{t("Si tu disposes des deux abonnements à la fois, le robot en tiendra compte : il ne te fera prendre aucun risque de doublons. Il ne te proposera donc pas la même issue d'un même match via l'abonnement \"International\" si elle a déjà été prise via l'abonnement \"France\", et inversement.")}</p>

            {!hasActiveSubscription && <p className="partOfPage">{t("Vous ne disposez pas encore d'abonnement actif à BSM. Pour profiter de l'intégralité de son offre et ainsi paramétrer vos critères personnels, recevoir tous les paris à prendre et en analyser les résultats, il vous suffit de choisir votre abonnement ci-dessous.")}</p>}

            <SubscriptionPlans t={t} setIsLoading={setIsLoading} isAxiosReady={isAxiosReady} user={user} proceedSubscription={handleClickOnProceedSubscription} manageSubscription={handleClickOnManageSubscription} />

            <div className="bookmakersContainer">
                <p>(1) {t("Liste des bookmakers de l'offre française")}</p>
                <FrenchBooksNotation t={t} />
            </div>

            <div className="bookmakersContainer">
                <p>(2) {t("Liste des bookmakers de l'offre internationale")}</p>
                <WorldBooksNotation t={t} />
            </div>
        </div>
    );
}

export default Subscription;