import React, { useEffect, useState } from 'react';

function MultiSelect({ t, id, value, options, label, onChange }) {
    const [isOpened, setIsOpened] = useState(false);
    const [allOptions, setAllOptions] = useState({});

    useEffect(() => {
        document.addEventListener('click', handleClick);

        let formattedOptions = {};
        if (Array.isArray(options)) {
            formattedOptions["-"] = [...options];
        } else {
            formattedOptions = { ...options };
        }

        for (const [title, booksInfo] of Object.entries(formattedOptions)) {
            formattedOptions[title] = booksInfo.map(bookInfo => {
                return {
                    ...bookInfo,
                    "selected": value.filter(v => typeof v !== "undefined").map(v => v.value).includes(bookInfo.value)
                }
            })
        }

        setAllOptions(formattedOptions);
        onChange(Object.values(formattedOptions).flat().filter(o => o.selected));
    }, []);

    const handleClick = (event) => {
        if (event.target.closest(`#${id}`)) {
            setIsOpened(true);
        } else {
            setIsOpened(false);
        }
    }

    const OnSelectAll = (title) => {
        const tmpOptions = { ...allOptions };
        if (title) {
            Object.values(tmpOptions[title]).forEach((option, index) => tmpOptions[title][index].selected = true);
        } else {
            Object.keys(tmpOptions).forEach(title => Object.values(tmpOptions[title]).forEach((option, index) => tmpOptions[title][index].selected = true));
        }

        setAllOptions(tmpOptions);
        onChange(Object.values(tmpOptions).flat().filter(o => o.selected));
    }

    const OnUnselectAll = (title) => {
        const tmpOptions = { ...allOptions };
        if (title) {
            Object.values(tmpOptions[title]).forEach((option, index) => tmpOptions[title][index].selected = false);
        } else {
            Object.keys(tmpOptions).forEach(title => Object.values(tmpOptions[title]).forEach((option, index) => tmpOptions[title][index].selected = false));
        }

        setAllOptions(tmpOptions);
        onChange(Object.values(tmpOptions).flat().filter(o => o.selected));
    }

    const OnToggleBook = (title, index) => {
        const tmpOptions = { ...allOptions };
        tmpOptions[title][index].selected = !tmpOptions[title][index].selected;

        setAllOptions(tmpOptions);
        onChange(Object.values(tmpOptions).flat().filter(o => o.selected));
    }

    return (
        <div className="multiSelect">
            <label for={id}>{label} :</label>
            <div id={id} className="multiSelect-values">
                <div className="values">
                    {
                        Object.values(allOptions).flat().filter(o => o.selected).map(o => {
                            return (<span key={o.value} className="book">{o.label}</span>);
                        })
                    }
                </div>

                <div className={isOpened ? "optionsContainer opened" : "optionsContainer"}>
                    <div className="bookContainer">
                        <div className="titleContainer">
                            <span className="title"></span>
                            <div className="buttons">
                                <span className="selectAll" onClick={() => OnSelectAll()}>Tout cocher</span>
                                - <span className="unselectAll" onClick={() => OnUnselectAll()}>Tout décocher</span>
                            </div>
                        </div>
                    </div>

                    {
                        Object.keys(allOptions).map(title => {
                            const optionsInfo = allOptions[title];

                            return (
                                <div key={title} className="bookContainer">
                                    <div className="titleContainer">
                                        <span className="title">{title}</span>
                                        <div className="buttons">
                                            <span className="selectAll" onClick={() => OnSelectAll(title)}>Tout cocher</span>
                                            - <span className="unselectAll" onClick={() => OnUnselectAll(title)}>Tout décocher</span>
                                        </div>
                                    </div>
                                    <div className="options">
                                        {
                                            optionsInfo.map((option, index) => {
                                                return <span key={option.value} className={option.selected ? "option selected" : "option"} onClick={() => OnToggleBook(title, index)}>{option.label}</span>
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default MultiSelect
