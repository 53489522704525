import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { AiFillEuroCircle, AiFillEdit } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { FaFileCsv } from "react-icons/fa6";
import FormField from '../components/FormField';
import Popup from '../components/Popup';
import toast from "react-hot-toast";
import DHMSFormField from '../components/DHMSFormField';
import { Tooltip } from "react-tooltip";
import { allTotalsMarkets, allTeamTotalsMarkets, formatBigNumber, stylizedBookName, allFrenchBooks, allWorldBooks, allSports, allMarkets, stylizedSport, pointsUnitsBySport, getPrintableBet, isBetWon, getPrintableDateTime, getShortPrintableDateTime, getDateForInput, getPrintableDuration } from "../utils";
import { t } from 'i18next';
import MultipleFormField from '../components/MultipleFormField';

function MyBets({ isAxiosReady, setIsLoading, setLoadingText }) {
    let yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    let inAMonthDate = new Date();
    inAMonthDate.setMonth(inAMonthDate.getMonth() + 1);

    const [bets, setBets] = useState([]);
    const [initialBetsData, setInitialBetsData] = useState([]);
    const [betsData, setBetsData] = useState([]);

    // Filters
    const [teamsNameFilter, setTeamsNameFilter] = useState("");
    const [takenFilter, setTakenFilter] = useState(true);
    const [notTakenFilter, setNotTakenFilter] = useState(false);
    const [V1Filter, setV1Filter] = useState(true);
    const [V2Filter, setV2Filter] = useState(true);
    const [sportsFilter, setSportsFilter] = useState([]);
    const [minMatchDateFilter, setMinMatchDateFilter] = useState(getDateForInput(yesterdayDate));
    const [maxMatchDateFilter, setMaxMatchDateFilter] = useState(getDateForInput(inAMonthDate));
    const [minSharedDateFilter, setMinSharedDateFilter] = useState();
    const [maxSharedDateFilter, setMaxSharedDateFilter] = useState();
    const [marketsFilter, setMarketsFilter] = useState([]);
    const [booksFilter, setBooksFilter] = useState([]);
    const [minOddFilter, setMinOddFilter] = useState(1);
    const [maxOddFilter, setMaxOddFilter] = useState(20);
    const [minBetFilter, setMinBetFilter] = useState(1);
    const [maxBetFilter, setMaxBetFilter] = useState(50000);
    const [minTRJFilter, setMinTRJFilter] = useState(50);
    const [maxTRJFilter, setMaxTRJFilter] = useState(150);
    const [minEVFilter, setMinEVFilter] = useState(-50);
    const [maxEVFilter, setMaxEVFilter] = useState(50);
    const [minLiquidityFilter, setMinLiquidityFilter] = useState(1);
    const [maxLiquidityFilter, setMaxLiquidityFilter] = useState(1000000);
    const [isCLVActiveFilter, setIsCLVActiveFilter] = useState(false);
    const [minCLVFilter, setMinCLVFilter] = useState(-100);
    const [maxCLVFilter, setMaxCLVFilter] = useState(100);
    const [resultsFilter, setResultsFilter] = useState("all");
    const [minTimeSinceOpeningFilter, setMinTimeSinceOpeningFilter] = useState(0);
    const [maxTimeSinceOpeningFilter, setMaxTimeSinceOpeningFilter] = useState(100 * 86400);
    const [minTimeUntilClosingFilter, setMinTimeUntilClosingFilter] = useState(0);
    const [maxTimeUntilClosingFilter, setMaxTimeUntilClosingFilter] = useState(100 * 86400);

    // Modals
    const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
    const [deletePopupData, setDeletePopupData] = useState();
    const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);
    const [editPopupData, setEditPopupData] = useState();

    const columns = [
        {
            name: t("Sport"), selector: row => {
                if (row.isV2) {
                    return <span className="newVersion">{stylizedSport[row.sport]}</span>;
                } else {
                    return stylizedSport[row.sport];
                }
            }, sortable: true, sortFunction: sortBySport
        },
        { name: t("Match"), selector: row => <>{row.homeTeamName.split(";")[0]}<br />{row.awayTeamName.split(";")[0]}</> },
        { name: t("Pari"), selector: row => <strong>{getPrintableBet(row.sport, row.marketName, row.side, row.homeTeamName, row.awayTeamName)}</strong> },
        {
            name: t("Date match"), selector: row => {
                return (<>
                    <span className="matchDate">{getPrintableDateTime(row.date)}</span>
                </>);
            }, sortable: true, sortFunction: sortByMatchDate
        },
        {
            name: t("Bookmaker"), selector: row => {
                return (<>
                    <span className="bookName">{row.bookName.split(";").map(bookName => stylizedBookName[bookName]).join(", ")}</span><br />
                    <small className="sharedDate">({t("Pris le X", { "date": getShortPrintableDateTime(row.sharedDate) })})</small>
                </>)
            }, sortable: true
        },
        {
            name: t("Cote"), selector: row => {
                const timeSinceOpening = row.openingDate ? getPrintableDuration((new Date(row.sharedDate).getTime() - new Date(row.openingDate).getTime()) / 1000) : "-";

                return (<>
                    <span className="odd">{parseFloat(row.odd).toFixed(2).replace(".", ",")}</span><br />
                    <small>({t("Opening X avant", { "timeSinceOpening": timeSinceOpening })})</small>
                </>)
            }, sortable: true
        },
        {
            name: t("Mise"), selector: row => {
                return (
                    <span className="iconAndText">
                        <AiFillEuroCircle />
                        <span>{parseFloat(row.bet).toFixed(2).replace(".", ",")}{t("€")}</span>
                    </span>
                );
            }, sortable: true, sortFunction: sortByEuros
        },
        {
            name: t("VS Pinnacle"), selector: row => {
                return <>
                    <b>{t("TRJ")} :</b> <span className="TRJ">{(row.TRJ.split(";")[0] * 100).toFixed(2).replace(".", ",")}%</span>
                    <br />
                    <b>{t("EV")} :</b> <span className="EV">{(row.EV.split(";")[0] * 100).toFixed(2).replace(".", ",")}%</span>
                    <br />
                    <b>{t("Liqui.")} :</b> <span className="liquidity">{row.pinnacleReference[row.pinnacleReference.length - 1]}{t("€")}</span>
                </>;
            }, sortable: true, sortFunction: sortByPinnacleValues
        },
        {
            name: t("CLV Pinnacle"), selector: row => {
                if (row.EVAtClosure) {
                    return (
                        <span className="CLV">
                            <span className={row.EVAtClosure > 0 ? "positive" : "negative"}>{(row.EVAtClosure * 100).toFixed(2).replace(".", ",")}%</span>
                            <small>
                                {row.closureOdds.map(closureOdd => parseFloat(closureOdd).toFixed(2).replace(".", ",")).join(" | ")}
                                {row.liquidities && " - " + formatBigNumber(row.liquidities) + t("€")}
                            </small>
                            <small>{t("le X", { "date": getShortPrintableDateTime(row.lastClosureGetTime) })}</small>
                        </span>
                    );
                } else {
                    return <i>{t("Non disponible")}</i>
                }
            }, sortable: true, sortFunction: sortByCLV
        },
        {
            name: t("Résultats"), selector: row => {
                if (row.isNotFound) {
                    return <i>{t("A venir")}</i>
                } else if (row.isRefunded && row.winner === "surrender") {
                    return (
                        <>
                            <span className="matchResult surrender">0{t("€")}</span><br />
                            <small>{t("Abandon / Report")}</small>
                        </>
                    )
                } else if (row.isRefunded) {
                    return (
                        <>
                            <span className="matchResult surrender">0{t("€")}</span><br />
                            <small>{t("Remboursé")}</small>
                        </>
                    )
                } else if (row.hasWon) {
                    return (
                        <>
                            <span className="matchResult won">+{(row.gain).toFixed(2).replace(".", ",")}{t("€")}</span><br />
                            <small>{row.results ? row.sport === "tennis" ? t("Score") + " : " + row.results.split(";").filter(r => r !== "0:0").map(r => r.split(":").join("-")).join(" / ") : t("Score") + " : " + row.results.split(";").join(" - ") : ""}</small>
                        </>
                    )
                } else {
                    return (
                        <>
                            <span className="matchResult lost">{parseFloat(row.gain).toFixed(2).replace(".", ",")}{t("€")}</span><br />
                            <small>{row.results ? row.sport === "tennis" ? t("Score") + " : " + row.results.split(";").filter(r => r !== "0:0").map(r => r.split(":").join("-")).join(" / ") : t("Score") + " : " + row.results.split(";").join(" - ") : ""}</small>
                        </>
                    )
                }
            }, sortable: true, sortFunction: sortByResults
        },
        { name: t("Actions"), selector: row => <span className="actions"><AiFillEdit onClick={() => handleEditLine(row)} /><MdDelete onClick={() => handleRemoveLine(row)} /></span>, sortable: true }
    ];

    // Filters
    useEffect(() => {
        let filteredData = initialBetsData;

        // Taken filter
        filteredData = filteredData.filter(bet => (bet.hasSucceedToBet && takenFilter) || (!bet.hasSucceedToBet && notTakenFilter));

        // Version filter
        filteredData = filteredData.filter(bet => (!bet.isV2 && V1Filter) || (bet.isV2 && V2Filter));

        // Sports filter
        if (sportsFilter.length > 0) {
            filteredData = filteredData.filter(bet => !sportsFilter.includes(bet.sport));
        }

        // Books filter
        if (booksFilter.length > 0) {
            filteredData = filteredData.filter(bet => !booksFilter.some(bookName => bet.bookName.split(", ").includes(bookName)));
        }

        // Markets filter
        if (marketsFilter.length > 0) {
            filteredData = filteredData.filter(bet => {
                return marketsFilter.some(completeMarketName => {
                    const [sport, marketName] = completeMarketName.split("_");
                    return !(marketName === bet.marketName && sport === bet.sport);
                });
            });
        }

        // Results
        if (resultsFilter !== "all") {
            filteredData = filteredData.filter(bet => {
                switch (resultsFilter) {
                    case "onlyWon":
                        return bet.winner === bet.side;
                    case "onlyLost":
                        return bet.winner !== null && bet.winner !== bet.side;
                    case "onlyWithResults":
                        return bet.winner !== null;
                    case "onlyWithoutResults":
                        return bet.winner === null && bet.results === null;
                    default:
                        return true;
                }
            });
        }

        // Odds filter
        filteredData = filteredData.filter(bet => parseFloat(bet.odd) >= parseFloat(minOddFilter) && parseFloat(bet.odd) <= parseFloat(maxOddFilter));

        // Bets filters
        filteredData = filteredData.filter(bet => parseInt(bet.bet) >= parseInt(minBetFilter) && parseInt(bet.bet) <= parseInt(maxBetFilter));

        // Liquidity VS Pinnacle filter
        filteredData = filteredData.filter(bet => {
            const pinnacleReference = bet.pinnacleReference[bet.pinnacleReference.length - 1];
            return parseInt(pinnacleReference) >= parseInt(minLiquidityFilter) && parseInt(pinnacleReference) <= parseInt(maxLiquidityFilter)
        });

        // TRJ / EV filters
        filteredData = filteredData.filter(bet => {
            const TRJ = parseFloat(bet.TRJ) * 100;
            const EV = parseFloat(bet.EV) * 100;

            return TRJ >= parseFloat(minTRJFilter) && TRJ <= parseFloat(maxTRJFilter)
                && EV >= parseFloat(minEVFilter) && EV <= parseFloat(maxEVFilter);
        });

        // CLV filters
        if (isCLVActiveFilter)
            filteredData = filteredData.filter(bet => parseFloat(bet.EVAtClosure) * 100 >= parseFloat(minCLVFilter) && parseFloat(bet.EVAtClosure) * 100 <= parseFloat(maxCLVFilter));

        // Match date filter
        if (minMatchDateFilter || maxMatchDateFilter) {
            const minMatchTime = new Date(minMatchDateFilter).getTime();
            let maxMatchTime = new Date(maxMatchDateFilter);
            maxMatchTime.setHours(23, 59, 59, 0);
            maxMatchTime = maxMatchTime.getTime();

            filteredData = filteredData.filter(bet => {
                const currentMatchTime = new Date(bet.date).getTime();
                return currentMatchTime >= minMatchTime && currentMatchTime <= maxMatchTime;
            });
        }

        // Shared date filter
        if (minSharedDateFilter || maxSharedDateFilter) {
            const minSharedTime = new Date(minSharedDateFilter).getTime();
            const maxSharedTime = new Date(maxSharedDateFilter).getTime();

            filteredData = filteredData.filter(bet => {
                const currentSharedTime = new Date(bet.date).getTime();
                return currentSharedTime >= minSharedTime && currentSharedTime <= maxSharedTime;
            });
        }

        // Filter on opening / closing
        filteredData = filteredData.filter(bet => {
            const sharedTime = new Date(bet.sharedDate).getTime() / 1000;

            let isOpeningOk = true;
            if (bet.openingDate) {
                const openingTime = new Date(bet.openingDate).getTime() / 1000;
                const timeSinceOpening = sharedTime - openingTime;

                const interpretedMinTimeSinceOpeningFilter = minTimeSinceOpeningFilter === 0 ? Number.MIN_SAFE_INTEGER : minTimeSinceOpeningFilter;
                isOpeningOk = timeSinceOpening >= interpretedMinTimeSinceOpeningFilter && timeSinceOpening <= maxTimeSinceOpeningFilter;
            }

            const matchTime = new Date(bet.date).getTime() / 1000;
            const timeUntilClosing = matchTime - sharedTime;

            const interpretedMinTimeUntilClosingFilter = minTimeUntilClosingFilter === 0 ? Number.MIN_SAFE_INTEGER : minTimeUntilClosingFilter;
            const isClosingOk = timeUntilClosing >= interpretedMinTimeUntilClosingFilter && timeUntilClosing <= maxTimeUntilClosingFilter;

            return isOpeningOk && isClosingOk;
        });

        // Filter on teams name
        if (teamsNameFilter.length > 0) {
            const textToSearch = teamsNameFilter.toString().toLowerCase();
            filteredData = filteredData.filter(bet => bet.homeTeamName.toLowerCase().includes(textToSearch) || bet.awayTeamName.toLowerCase().includes(textToSearch));
        }

        setBetsData(filteredData);
    }, [initialBetsData, sportsFilter, teamsNameFilter, takenFilter, notTakenFilter, V1Filter, V2Filter, minMatchDateFilter, maxMatchDateFilter, minSharedDateFilter, maxSharedDateFilter, marketsFilter, booksFilter, minOddFilter, maxOddFilter, minBetFilter, maxBetFilter, minTRJFilter, maxTRJFilter, minEVFilter, maxEVFilter, minLiquidityFilter, maxLiquidityFilter, isCLVActiveFilter, minCLVFilter, maxCLVFilter, resultsFilter, minTimeSinceOpeningFilter, maxTimeSinceOpeningFilter, minTimeUntilClosingFilter, maxTimeUntilClosingFilter]);

    // Get initial datas
    useEffect(() => {
        if (isAxiosReady) {
            setIsLoading(true);

            const startTime = new Date(minMatchDateFilter).getTime();
            const endTime = new Date(maxMatchDateFilter).getTime();

            const filters = [`startDate:${startTime}`, `endDate:${endTime}`];

            if (!notTakenFilter) {
                filters.push(`hasSucceedToBet:1`);
            }

            axios.get(`/bet?filters=${filters.join(",")}`).then(response => {
                setBets(response.data);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(true);
            setLoadingText("Chargement de vos paris...");
        }
    }, [isAxiosReady, minMatchDateFilter, maxMatchDateFilter, notTakenFilter]);

    // Transform datas to readable datas
    useEffect(() => {
        const localBetsData = bets.map(bet => {
            const restructuredBet = {
                id: bet.id,
                pinnacleEventId: bet.pinnacleEventId,
                eventId: bet.eventId,
                isV2: bet.isV2 === 1,
                marketName: bet.marketName,
                sport: bet.sport,
                competition: bet.competition,
                hasSucceedToBet: bet.hasSucceedToBet === 1,
                side: bet.side,
                homeTeamName: bet.homeTeamName.split(";")[0],
                awayTeamName: bet.awayTeamName.split(";")[0],
                date: bet.pinnacleClosureDate,
                sharedDate: bet.sharedDate,
                bookName: bet.bookName,
                odd: parseFloat(bet.odd.split(";")[0]),
                bet: bet.bet,
                TRJ: bet.TRJ,
                EV: bet.EV,
                FO: bet.FO,
                TRJWithMPTO: bet.TRJWithMPTO,
                EVWithMPTO: bet.EVWithMPTO,
                FOWithMPTO: bet.FOWithMPTO,
                TRJWithEM: bet.TRJWithEM,
                EVWithEM: bet.EVWithEM,
                FOWithEM: bet.FOWithEM,
                pinnacleReference: bet.pinnacleReference.split(";"),
                openingDate: bet.openingDate,
                closureOdds: bet.closureOdds,
                liquidities: bet.liquidities ? bet.liquidities[bet.marketName] : "",
                TRJAtClosure: bet.TRJAtClosure ? bet.TRJAtClosure : null,
                EVAtClosure: bet.EVAtClosure ? bet.EVAtClosure : null,
                FOAtClosure: bet.FOAtClosure ? bet.FOAtClosure : null,
                lastClosureGetTime: bet.lastClosureGetTime ? bet.lastClosureGetTime : null,
                results: bet.results ? bet.results : null,
                winner: bet.winner ? bet.winner : null,
                resultByMarket: bet.resultByMarket ? bet.resultByMarket : null
            };

            const { hasWon, isRefunded, isNotFound, benefit } = isBetWon(bet);
            restructuredBet.hasWon = hasWon;
            restructuredBet.isRefunded = isRefunded;
            restructuredBet.isNotFound = isNotFound;
            restructuredBet.gain = benefit;

            return restructuredBet;
        });

        setBetsData(localBetsData);
        setInitialBetsData(localBetsData);
    }, [bets]);

    const handleEditLine = (rowData) => {
        setIsEditPopupOpened(true);
        setEditPopupData(rowData);
    }

    const handleConfirmEditLine = (newData) => {
        setIsEditPopupOpened(false);

        axios.put("/bet", {
            id: newData.id,
            odd: newData.odd,
            bet: newData.bet,
            bookName: newData.bookName,
            hasSucceedToBet: newData.hasSucceedToBet
        }).then(response => {
            const updatedItem = response.data;

            const indexOfUpdatedElement = bets.findIndex(row => row.id === updatedItem.id);
            let newBets = [...bets];
            newBets[indexOfUpdatedElement] = updatedItem;
            setBets(newBets);

            setEditPopupData(null);
            toast.success(t("Le pari a bien été modifié !"));
        }).catch(err => {
            console.log(err);
            toast.error(t("Le pari n'a pas pu être modifié !"));
        });
    }

    const handleRemoveLine = (rowData) => {
        setIsDeletePopupOpened(true);
        setDeletePopupData(rowData);
    }

    const handleConfirmRemoveLine = async () => {
        setIsDeletePopupOpened(false);

        axios.delete("/bet?id=" + deletePopupData.id).then(response => {
            setInitialBetsData(initialBetsData.filter(bet => bet.id !== deletePopupData.id));
            setDeletePopupData(null);
            toast.success(t("Le pari a bien été supprimé !"));
        }).catch(err => {
            console.log(err);
            toast.error(t("Le pari n'a pas pu être supprimé !"));
        });

    }

    const convertArrayOfObjectsToCSV = () => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const columns = {
            "sport": t("Sport"),
            "isV2": t("V2"),
            "marketName": t("Nom du marché"),
            "date": t("Date du match"),
            "competition": t("Compétition"),
            "homeTeamName": t("Équipe à domicile"),
            "awayTeamName": t("Équipe à l'extérieur"),
            "bet": t("Mise"),
            "odd": t("Cote"),
            "side": t("Pari joué"),
            "bookName": t("Book"),
            "TRJWithMPTO": t("TRJ (MPTO)"),
            "EVWithMPTO": t("EV (MPTO)"),
            "FOWithMPTO": t("FO (MPTO)"),
            "TRJWithEM": t("TRJ (EM)"),
            "EVWithEM": t("EV (EM)"),
            "FOWithEM": t("FO (EM)"),
            "pinnacleReference": t("Références Pinnacle"),
            "openingDate": t("Date opening"),
            "sharedDate": t("Date partage"),
            "hasSucceedToBet": t("A réussi à parier"),
            "results": t("Résultats"),
            "winner": t("Vainqueur"),
            "liquidities": t("Liquidités"),
            "lastClosureGetTime": t("Date de dernière récupération de CLV"),
            "closureOdds": t("Cotes de clôture"),
            "TRJAtClosure": t("TRJ de clôture"),
            "EVAtClosure": t("EV de clôture"),
            "FOAtClosure": t("FO de clôture"),
            "gain": t("Gain / Perte")
        };

        result = '';
        result += Object.values(columns).join(columnDelimiter);
        result += lineDelimiter;

        betsData.forEach(item => {
            let ctr = 0;
            Object.keys(columns).forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let toAdd = item[key];
                if (toAdd) {
                    if (Array.isArray(toAdd)) {
                        toAdd = toAdd.join(" / ");
                    } else {
                        toAdd = toAdd.toString().replaceAll(",", "");
                    }
                }

                result += toAdd;
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    const downloadCSV = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV();
        if (csv == null) return;

        const filename = 'bets.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    return (
        <div id="myBets">
            <h1>{t("Mes paris")}</h1>

            <div className="filtersContainer">
                <div className="filter takenFilterContainer">
                    <MultipleFormField type={["checkbox", "checkbox"]} label={[t("Montrer les paris"), ""]} afterLabel={[t("pris"), t("non pris")]} id={["takenFilter", "notTakenFilter"]} onChange={[setTakenFilter, setNotTakenFilter]} checked={[takenFilter, notTakenFilter]} />
                </div>

                <div className="filter versionFilterContainer">
                    <MultipleFormField type={["checkbox", "checkbox"]} label={[t("Montrer uniquement les paris de la"), ""]} afterLabel={[t("V1"), t("V2")]} id={["V1Filter", "V2Filter"]} onChange={[setV1Filter, setV2Filter]} checked={[V1Filter, V2Filter]} />
                </div>

                <div className="filter sportsFilterContainer">
                    <FormField t={t} id="sportsFilter" type="select" options={allSports} multiple={true} label={t("Sports exclus")} value={sportsFilter} onChange={setSportsFilter} />
                </div>

                <div className="filter teamsNameFilterContainer">
                    <FormField t={t} id="teamsNameFilter" type="text" label={t("Nom d'équipe / de joueur")} value={teamsNameFilter} onChange={setTeamsNameFilter} />
                </div>

                <div className="filter matchDateFilterContainer">
                    <MultipleFormField type={["date", "date"]} label={[t("Date du match") + " : " + t("du"), t("au")]} id={["matchDateFilter", "endMatchDateFilter"]} onChange={[setMinMatchDateFilter, setMaxMatchDateFilter]} value={[minMatchDateFilter, maxMatchDateFilter]} />
                </div>

                <div className="filter sharedDateFilterContainer">
                    <MultipleFormField type={["date", "date"]} label={[t("Date de partage") + " : " + t("du"), t("au")]} id={["sharedDateFilter", "endSharedDateFilter"]} onChange={[setMinSharedDateFilter, setMaxSharedDateFilter]} value={[minSharedDateFilter, maxSharedDateFilter]} />
                </div>

                <div className="filter marketsFilterContainer">
                    <FormField t={t} id="marketsFilter" type="select" options={allMarkets} multiple={true} label={t("Marchés exclus")} value={marketsFilter} onChange={setMarketsFilter} />
                </div>

                <div className="filter booksFilterContainer">
                    <FormField t={t} id="booksFilter" type="select" options={[...allFrenchBooks, ...Object.values(allWorldBooks).flat()]} multiple={true} label={t("Bookmakers exclus")} value={booksFilter} onChange={setBooksFilter} />
                </div>

                <div className="filter oddFilterContainer">
                    <MultipleFormField type={["number", "number"]} label={[t("Cote") + " : " + t("de"), t("to")]} id={["minOddFilter", "maxOddFilter"]} onChange={[setMinOddFilter, setMaxOddFilter]} min={["1", "1"]} max={["20", "20"]} step={["0.01", "0.01"]} value={[minOddFilter, maxOddFilter]} />
                </div>

                <div className="filter betFilterContainer">
                    <MultipleFormField type={["number", "number"]} label={[t("Mise") + " : " + t("de"), t("to")]} afterLabel={[t("€"), t("€")]} id={["minBetFilter", "maxBetFilter"]} onChange={[setMinBetFilter, setMaxBetFilter]} min={["1", "1"]} step={["1", "1"]} value={[minBetFilter, maxBetFilter]} />
                </div>

                <div className="filter TRJFilterContainer">
                    <MultipleFormField type={["number", "number"]} label={[t("TRJ") + " : " + t("de"), t("to")]} afterLabel={["%", "%"]} id={["minTRJFilter", "maxTRJFilter"]} onChange={[setMinTRJFilter, setMaxTRJFilter]} min={["50", "50"]} step={["0.01", "0.01"]} value={[minTRJFilter, maxTRJFilter]} />
                </div>

                <div className="filter EVFilterContainer">
                    <MultipleFormField type={["number", "number"]} label={[t("EV") + " : " + t("de"), t("to")]} afterLabel={["%", "%"]} id={["minEVFilter", "maxEVFilter"]} onChange={[setMinEVFilter, setMaxEVFilter]} min={["0", "0"]} step={["0.01", "0.01"]} value={[minEVFilter, maxEVFilter]} />
                </div>

                <div className="filter liquidityFilterContainer">
                    <MultipleFormField type={["number", "number"]} label={[t("Liquidité") + " : " + t("de"), t("to")]} afterLabel={[t("€"), t("€")]} id={["minliquidityFilter", "maxliquidityFilter"]} onChange={[setMinLiquidityFilter, setMaxLiquidityFilter]} min={["0", "0"]} step={["5", "5"]} value={[minLiquidityFilter, maxLiquidityFilter]} />
                </div>

                <div className="filter CLVFilterContainer">
                    <label for="CLVFilter">{t("CLV")} : </label>
                    <input type="checkbox" id="isCLVActiveFilter" onChange={e => setIsCLVActiveFilter(!isCLVActiveFilter)} checked={isCLVActiveFilter} />
                    {t("de")} <input type="number" step="0.01" id="minCLVFilter" disabled={!isCLVActiveFilter} onChange={e => setMinCLVFilter(e.target.value)} value={minCLVFilter} />%
                    {" "}{t("to")} <input type="number" step="0.01" id="maxCLVFilter" disabled={!isCLVActiveFilter} onChange={e => setMaxCLVFilter(e.target.value)} value={maxCLVFilter} />%
                </div>

                <div className="filter resultsFilterContainer">
                    <label for="resultsFilter">{t("Résultats")} : </label>
                    <select id="resultsFilter" onChange={e => setResultsFilter(e.target.value)}>
                        <option value="all">{t("Tous")}</option>
                        <option value="onlyWon">{t("Seulement les gagnants")}</option>
                        <option value="onlyLost">{t("Seulement les perdants")}</option>
                        <option value="onlyWithResults">{t("Seulement ceux ayant un résultats")}</option>
                        <option value="onlyWithoutResults">{t("Seulement ceux n'ayant pas de résultats")}</option>
                    </select>
                </div>

                <div className="filter timeSinceOpeningFilter">
                    <DHMSFormField t={t} id="minTimeSinceOpening" label={t("Temps depuis l'opening : de ")} value={minTimeSinceOpeningFilter} onChange={setMinTimeSinceOpeningFilter} />
                    <DHMSFormField t={t} id="maxTimeSinceOpening" label={t("to")} value={maxTimeSinceOpeningFilter} onChange={setMaxTimeSinceOpeningFilter} />
                </div>

                <div className="filter timeUntilClosingFilter">
                    <DHMSFormField t={t} id="minTimeUntilClosing" label={t("Temps jusqu'au closing : de ")} value={minTimeUntilClosingFilter} onChange={setMinTimeUntilClosingFilter} />
                    <DHMSFormField t={t} id="maxTimeUntilClosing" label={t("to")} value={maxTimeUntilClosingFilter} onChange={setMaxTimeUntilClosingFilter} />
                </div>
            </div>

            <Tooltip t={t} anchorSelect="#downloadBets">{t("Télécharger les paris du tableau au format CSV")}</Tooltip>
            <FaFileCsv id="downloadBets" onClick={downloadCSV} size="30" />

            <DataTable columns={columns} data={betsData} conditionalRowStyles={conditionalRowStyles} defaultSortFieldId="4" defaultSortAsc={true} fixedHeader pagination paginationPerPage="50" paginationRowsPerPageOptions={[50, 100, 200, 500, 1000]} />

            <ConfirmDeletePopup t={t} isOpened={isDeletePopupOpened} setIsOpened={setIsDeletePopupOpened} confirmDelete={handleConfirmRemoveLine} />
            <ConfirmEditPopup t={t} isOpened={isEditPopupOpened} setIsOpened={setIsEditPopupOpened} confirmEdit={handleConfirmEditLine} data={editPopupData} />
        </div>
    )
}

export default MyBets;

const ConfirmDeletePopup = ({ t, isOpened, setIsOpened, confirmDelete }) => {
    return (
        <Popup t={t} isOpened={isOpened} setIsOpened={setIsOpened} confirmAction={confirmDelete} title={t("Suppression d'un pari")}>
            <>
                <p>{t("Êtes-vous sûr de vouloir supprimer ce pari de votre historique ? Cette action est irréversible.")}</p>
            </>
        </Popup>
    );
}

const ConfirmEditPopup = ({ t, isOpened, setIsOpened, confirmEdit, data }) => {
    const [hasSucceedToBet, setHasSucceedToBet] = useState(data ? data.hasSucceedToBet : null);
    const [bookName, setBookName] = useState(data ? data.bookName : null);
    const [odd, setOdd] = useState(data ? data.odd : null);
    const [bet, setBet] = useState(data ? data.bet : null);

    useEffect(() => {
        if (data) {
            setHasSucceedToBet(data.hasSucceedToBet);
            setBookName(data.bookName.split(";")[0]);
            setOdd(data.odd);
            setBet(data.bet);
        }
    }, [data]);

    const handleConfirmEdit = () => {
        confirmEdit({
            ...data,
            hasSucceedToBet: hasSucceedToBet,
            bookName: bookName,
            odd: odd,
            bet: bet
        });
    }

    return (
        <Popup t={t} isOpened={isOpened} setIsOpened={setIsOpened} confirmAction={handleConfirmEdit} title={t("Modification d'un pari")}>
            <>
                <FormField t={t} type="checkbox" id="hasSucceedToBet" label={t("Pari pris")} checked={hasSucceedToBet} onChange={setHasSucceedToBet} />
                <FormField t={t} type="select" id="bookName" label={t("Bookmaker")} options={[...allFrenchBooks, ...Object.values(allWorldBooks).flat()]} value={bookName} onChange={setBookName} />
                <FormField t={t} type="number" min="1" step="0.01" id="odd" label={t("Cote")} value={odd} onChange={setOdd} />
                <FormField t={t} type="number" min="1" step="1" id="bet" label={t("Mise")} value={bet} onChange={setBet} />
            </>
        </Popup>
    );
}

const conditionalRowStyles = [
    {
        when: row => row.hasSucceedToBet === false,
        classNames: ['notTaken'],
    },
];

const sortBySport = (rowA, rowB) => {
    const a = rowA.sport;
    const b = rowB.sport;

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

const sortByEuros = (rowA, rowB) => {
    const a = parseFloat(rowA.bet);
    const b = parseFloat(rowB.bet);

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

const sortByPinnacleValues = (rowA, rowB) => {
    const a = parseFloat(rowA.TRJ) + parseFloat(rowA.EV);
    const b = parseFloat(rowB.TRJ) + parseFloat(rowB.EV);

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

const sortByCLV = (rowA, rowB) => {
    const a = parseFloat(rowA.EVAtClosure ? rowA.EVAtClosure : Number.MIN_SAFE_INTEGER);
    const b = parseFloat(rowB.EVAtClosure ? rowB.EVAtClosure : Number.MIN_SAFE_INTEGER);

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

const sortByMatchDate = (rowA, rowB) => {
    const a = new Date(rowA.date).getTime();
    const b = new Date(rowB.date).getTime();

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

const sortByResults = (rowA, rowB) => {
    const a = rowA.winner === rowA.side ? rowA.bet * rowA.odd - rowA.bet : 0;
    const b = rowB.winner === rowB.side ? rowB.bet * rowB.odd - rowB.bet : 0;

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}