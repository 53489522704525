import React from 'react'

function CGV({ t }) {
    return (
        <div id="CGV">
            <h1>Conditions Générales de Vente</h1>

            <h3>1. Préambule</h3>
            <p>Bet-Set-Match.com est un site édité par la société Hypora (n° SIREN 984317883).</p>
            <p>Bet-Set-Match.com est hébergé par OVHcloud.</p>
            <p>L'activité principale de Hypora est de proposer à la vente une application web de détection de value bets.</p>
            <p>Tous les services disponibles sur le site Bet-Set-Match.com s'adressent exclusivement à des personnes physiques non commerçantes, n'agissant pas dans un cadre professionnel, et majeures (conformément au Décret n° 2007-728 du 7 mai 2007 relatif à l'organisation et à l'exploitation des jeux de pronostics sportifs autorisés par l'article 42 de la loi de finances pour 1985 et modifiant le décret n° 85-390 du 1er avril 1985).</p>
            <p>La fourniture des services est subordonnée à l'acceptation des présentes Conditions Générales de Vente.</p>

            <h3>2. Conditions d'abonnement aux services</h3>
            <p>L'acheteur qui souhaite s'abonner aux services doit avoir pris connaissance des Conditions Générales de Vente et s'engage à les respecter. Sauf décision contraire du Site, l'abonnement aux différents services est payant.</p>
            <p>Les jeux d'argent étant interdit au mineur, l'utilisateur reconnaît être majeur.</p>

            <h3>3. Commande</h3>
            <p>Pour accéder à l'application web, l'acheteur doit souscrire sur le site à un abonnement mensuel. Le paiement est possible par les plateformes de paiements sécurisés de cartes bancaires ou Paypal via Stripe. Un courrier électronique de confirmation est envoyé à l'acheteur. Il aura désormais accès à l'application web pendant la durée de son abonnement. </p>
            <p>L'acheteur a l'interdiction formelle de reproduire, publier ou communiquer les value bets proposés par Bet-Set-Match.com à des tiers.</p>
            <p>Hypora se réserve le droit d'annuler toute commande d'un acheteur avec lequel il existerait un litige relatif au paiement d'une commande antérieure.</p>

            <h3>4. Garantie et remboursement</h3>
            <p>Compte tenu du caractère particulier des paris sportifs, l'acheteur accepte expressément que les transactions d'achat de value bets ne soient pas soumises à la condition de délai de rétractation de 7 jours prévue au bénéfice de l'acheteur d'un produit dans le cadre d'une vente à distance réalisée entre un particulier et un professionnel.</p>

            <h3>5. Respect de la vie privée</h3>
            <p>Les données collectées via le site sont exclusivement destinées à Bet-Set-Match.com.</p>
            <p>Bet-Set-Match.com s'engage à ne pas communiquer ces données à des tiers.</p>
            <p>Ces données personnelles ont pour objet d'identifier les abonnés aux services et de gérer leurs abonnements.</p>
            <p>Conformément à la loi « Informatique et Libertés » du 6 janvier 1978, l'acheteur dispose d'un droit d'accès, de rectification et d'opposition, relatif aux données le concernant. Pour exercer ce droit, l'acheteur a la possibilité d'envoyer un courrier électronique au site via la rubrique « Contact ».</p>
            <p>Chaque acheteur garantit que les données qu'il communique sont exactes, complètes et à jour.</p>
            <p>L'acheteur autorise expressément Bet-Set-Match.com à conserver les différentes opérations effectuées par lui, notamment pour répondre à ses obligations contractuelles, légales ou réglementaires. Le cas échéant, l'acheteur autorise par avance Bet-Set-Match.com à transmettre la description des dites opérations afin de répondre à toute obligation contractuelle ou toute injonction judiciaire ou administrative.</p>

            <h3>6. Responsabilité</h3>
            <p>Bet-Set-Match.com n'est pas un site de paris d'argent. Toutes les informations diffusées sur ce site sont fournies uniquement à titre informatif. L'interprétation et l'utilisation de ces informations relèvent de la responsabilité individuelle.</p>
            <p>Bet-Set-Match.com ne saurait être tenu pour responsable de l'utilisation faite par l'acheteur de ses value bets, ou de toutes autres informations fournies par Bet-Set-Match.com, sur des sites de paris ou de jeux d'argent.</p>
            <p>Il appartient également à l'acheteur de vérifier que les paris sportifs sont légaux dans son pays de résidence.</p>

            <h3>7. Usage</h3>
            <p>L'acheteur reconnaît être parfaitement informé de la possibilité pour Bet-Set-Match.com de suspendre et/ou de modifier temporairement ou définitivement ses services à tout moment, en raison de contraintes techniques ou réglementaires.</p>
            <p>L'acheteur reconnaît utiliser les services qui lui sont offerts « en l'état », à ses risques et périls et ce, en parfaite connaissance de cause.</p>
            <p>Bet-Set-Match.com ne garantit pas que ses services soient exempts de bugs ou autres malfaçons, une erreur de programmation ou un incident technique étant toujours possible.</p>
            <p>Par ailleurs, l'acheteur doit seul s'assurer de la compatibilité de ses matériels, logiciels et connexions avec les services offerts.</p>
            <p>Compte tenu de cet état de fait reconnu et accepté par l'acheteur, Bet-Set-Match.com ne pourra être tenu pour responsable de l'impossibilité temporaire ou définitive pour l'acheteur d'accéder à tout ou partie des services.</p>
            <p>De même, Bet-Set-Match.com ne pourra être tenu pour responsable des éventuelles dégradations de matériel, logiciel ou données (exemple : contamination virale) subies par l'acheteur du fait de son utilisation des services.</p>

            <h3>8. Propriété intellectuelle</h3>
            <p>Tous les textes reproduits sur Bet-Set-Match.com sont réservés au titre du droit d'auteur ainsi qu'au titre de la propriété intellectuelle et pour le monde entier. À ce titre et conformément aux dispositions du Code de la Propriété Intellectuelle, seule l'utilisation pour un usage privé est autorisée sous réserve de dispositions différentes voire plus restrictives du Code de la Propriété Intellectuelle. Toute autre utilisation est constitutive de contrefaçon et sanctionnée au titre de la Propriété Intellectuelle sans autorisation préalable de Bet-Set-Match.com.</p>

            <h3>9. Résiliation par Bet-Set-Match.com</h3>
            <p>Bet-Set-Match.com peut, à tout moment, en cas de non-respect par l'acheteur des présentes Conditions Générales de Vente, suspendre et/ou résilier immédiatement son abonnement sans possibilité de remboursement.</p>
            <p>L'acheteur s'expose à de telles mesures en particulier dans les cas suivants, sans que cette liste ne soit limitative :</p>
            <ul>
                <li>Présomption d'utilisation frauduleuse des moyens de paiement d'un tiers ;</li>
                <li>Atteinte aux droits de Bet-Set-Match.com ;</li>
                <li>Violation des lois et règlements en vigueur.</li>
            </ul>
            <p>En cas de résiliation, les données fournies par l'acheteur seront détruites dans un délai de 12 mois, à l'exception de celles dont la loi impose la conservation à Bet-Set-Match.com.</p>

            <h3>10. Règlement des litiges</h3>
            <p>Les présentes Conditions Générales de Vente sont régies exclusivement par le droit français.</p>
            <p>L'acheteur reconnaît que les données enregistrées par Bet-Set-Match.com font foi et constituent la preuve de l'ensemble des transactions passées.</p>
            <p>En cas de litige survenant dans le cadre des services fournis par Bet-Set-Match.com, l'acheteur aura la possibilité, avant toute action en justice, de rechercher une solution amiable, avec l´aide d'une association de consommateurs ou de tout autre conseil de son choix.</p>
        </div>
    )
}

export default CGV
