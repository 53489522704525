import React from 'react'
import { Link } from 'react-router-dom';

function PaymentFailed({ t }) {
    return (
        <div id="paymentFailed">
            <h1>{t("Paiement échoué !")}</h1>

            <p>{t("Votre paiement n'a pas abouti, veuillez réessayer en cliquant")} <Link to="/subscription">{t("ici")}</Link> !</p>
        </div>
    )
}

export default PaymentFailed
