import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer({ t, isAuthenticated }) {
    return (
        <footer>
            <div className="content">
                <div className="column">
                    <h1>{t("Value betting")}</h1>
                    <div className="items">
                        <HashLink to="/valueBetting#concept"><h2>{t("Le concept")}</h2></HashLink>
                        <HashLink to="/valueBetting#realProbability"><h2>{t("La probabilité réelle d'un événement")}</h2></HashLink>
                        <HashLink to="/valueBetting#booksMargin"><h2>{t("La marge des bookmakers")}</h2></HashLink>
                        <HashLink to="/valueBetting#pinnacle"><h2>{t("Notre référence : Pinnacle")}</h2></HashLink>
                        <HashLink to="/valueBetting#CLV"><h2>{t("La notion de CLV")}</h2></HashLink>
                        <HashLink to="/valueBetting#surebet"><h2>{t("Le surebet")}</h2></HashLink>
                        <HashLink to="/valueBetting#limits"><h2>{t("Les limites")}</h2></HashLink>
                    </div>
                </div>
                <div className="column">
                    <h1>{t("Informations")}</h1>
                    <div className="items">
                        <Link to="/FAQ"><h2>{t("Foire aux questions")}</h2></Link>
                        {isAuthenticated ? "" : <Link to="/pricing"><h2>{t("Tarifs")}</h2></Link>}
                        <Link to="/contact"><h2>{t("Nous contacter")}</h2></Link>
                        <Link to="/CGV"><h2>{t("Conditions Générales de Vente")}</h2></Link>
                        <Link to="/ML"><h2>{t("Mentions légales")}</h2></Link>
                    </div>
                </div>
            </div>
        </footer>
    )

    /*
    <div className="column">
        <h1>Outils</h1>
        <div className="items">
            <Link to="/tools"><h2>Calculateur de surebet</h2></Link>
            <Link to="/tools"><h2>Calculateur de value</h2></Link>
            <Link to="/tools"><h2>...</h2></Link>
        </div>
    </div>
    */
}

export default Footer
