import React from 'react'

function Popup(props) {
    const { t, isOpened, setIsOpened, confirmAction, title, buttons } = props;

    return (
        <div className={"popupContainer" + (isOpened ? " opened" : "")}>
            <div className="popup">
                <div className="closeContainer">
                    <span className="close" onClick={() => setIsOpened(false)}>&times;</span>
                </div>

                <h2 className="title">{title}</h2>

                <div className="content">{props.children}</div>

                <div className="actions">
                    {
                        buttons ? (
                            buttons.map((button, index) => {
                                <button key={index} className={button.class} onClick={button.action}>{button.text}</button>
                            })
                        ) : (
                            <>
                                <button className="btn success" onClick={confirmAction}>{t("Confirmer")}</button>
                                <button className="btn error" onClick={() => setIsOpened(false)}>{t("Annuler")}</button>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default Popup
