import React, { useEffect, useState } from 'react'
import FormField from '../components/FormField'
import axios from 'axios'
import toast from "react-hot-toast";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Contact({ t, user, setIsLoading, setLoadingText }) {
    const [sender, setSender] = useState(user && user.email ? user.email : "");
    const [content, setContent] = useState("");
    const [contactErrors, setContactErrors] = useState([]);

    useEffect(() => {
        if (user && user.email)
            setSender(user.email);
    }, [user]);


    const handleSendMail = (e) => {
        e.preventDefault();

        let errors = []
        if (!sender || !content)
            errors.push(t("Veuillez saisir tous les champs"));
        else {
            if (!sender.match(emailRegex))
                errors.push(t("L'email saisi n'est pas correct"));
            if (content.length < 10)
                errors.push(t("Le message saisi doit faire plus de 10 caractères"));
        }

        if (errors.length > 0) {
            setContactErrors(errors);
        }
        else {
            setIsLoading(true);
            setLoadingText(t("Vérification de vos informations..."));

            axios.post("util/contact", { sender, content }).then((result) => {
                setContactErrors([]);
                setIsLoading(false);
                toast.success(t("Le message a bien été envoyé !"));
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
                toast.error(t("Le message n'a pas pu être envoyé !"));
            });
        }
    }

    return (
        <div id="contact">
            <h1>{t("Une question ? Une remarque ?")} <strong>{t("Contacte-nous")}</strong> !</h1>

            <div className="contactMethodsContainer">
                <div className="emailContainer contactMethodContainer">
                    <h2><img src="./images/mail.png" alt="telegram" /> {t("Par email")}</h2>
                    <div className="link">
                        <a href="mailto:bet.set.match.bsm@gmail.com" target="_blank" rel="noreferrer">bet.set.match.bsm@gmail.com</a>
                    </div>
                </div>

                <div className="telegramContainer contactMethodContainer">
                    <h2><img src="./images/telegram_simple.png" alt="telegram" /> {t("Par Telegram")}</h2>
                    <div className="link">
                        <a href="https://telegram.me/alexandrebsm" target="_blank" rel="noreferrer">@AlexandreBSM</a>
                    </div>
                    <div className="link">
                        <a href="https://telegram.me/benjaminbsm" target="_blank" rel="noreferrer">@BenjaminBSM</a><br />
                    </div>
                </div>

                <div className="communityContainer contactMethodContainer">
                    <h2><img src="./images/telegram_community.png" alt="telegram" /> {t("Rejoins la communauté")}</h2>
                    <div className="telegramLinksContainer">
                        <div className="langContainer">
                            <div className="lang"><img src="./images/france.png" alt="french" /></div>
                            <div className="links">
                                <div className="link">
                                    <a href="https://t.me/+gC8elKvmN0E2ZTk0" target="_blank" rel="noreferrer">{t("Annonces officielles")}</a><br />
                                </div>
                                <div className="link">
                                    <a href="https://t.me/+fo89d_LQ9ZMxMzlk" target="_blank" rel="noreferrer">{t("Hotline & Discussion")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="langContainer">
                            <div className="lang"><img src="./images/england.png" alt="english" /></div>
                            <div className="links">
                                <div className="link">
                                    <a href="https://t.me/+NFWzQsqyoFc0OGI0" target="_blank" rel="noreferrer">{t("Annonces officielles")}</a><br />
                                </div>
                                <div className="link">
                                    <a href="https://t.me/+OfV69uXEHqM5MjNk" target="_blank" rel="noreferrer">{t("Hotline & Discussion")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
