import React, { useState } from 'react'
import FormField from '../components/FormField'
import axios from 'axios'
import { useNavigate, Link } from "react-router-dom";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function Register({ t, login, setIsLoading, setLoadingText }) {
    const navigate = useNavigate();

    const handleClickOnLogin = () => {
        navigate("/login", { replace: true });
    }

    // REGISTER
    const [email, setEmail] = useState(/*"test@test.com"*/);
    const [username, setUsername] = useState(/*"test"*/);
    const [registerPassword, setRegisterPassword] = useState(/*"test"*/);
    const [passwordConfirmation, setPasswordConfirmation] = useState(/*"test"*/);
    const [registerErrors, setRegisterErrors] = useState();
    const handleRegister = (e) => {
        e.preventDefault();

        let errors = []
        if (!email || !username || !registerPassword || !passwordConfirmation)
            errors.push(t("Veuillez saisir tous les champs"));
        else {
            if (!email.match(emailRegex))
                errors.push("L'email n'existe pas");
            if (username.length < 3)
                errors.push("Le pseudo doit contenir au minimum 3 caractères");
            if (registerPassword.length < 6)
                errors.push("Le mot de passe doit contenir au minimum 6 caractères");
            if (passwordConfirmation !== registerPassword)
                errors.push("Les deux mots de passe ne correspondent pas");
        }

        if (errors.length > 0) {
            setRegisterErrors(errors);
        }
        else {
            setIsLoading(true);
            setLoadingText(t("Vérification de vos informations..."));

            axios.post("user", { email, username, password: registerPassword, passwordConfirmation }).then((result) => {
                login(result.data.token, result.data.user);
                navigate("/", { replace: true });
                setRegisterErrors([]);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setRegisterErrors(err.response.data.errors);
                setIsLoading(false);
            });
        }
    }

    return (
        <div id="register">
            <h1>{t("Commence ta")} <strong>{t("période d’essai gratuite")}</strong></h1>

            <p className="alreadyHaveAccount">{t("Tu as déjà un compte ?")} <strong onClick={handleClickOnLogin}>{t("Connecte-toi !")}</strong></p>

            <h2><hr /><span>{t("Création de compte")}</span></h2>

            <form onSubmit={handleRegister}>
                <div className="formContent">
                    <div className="rightSide">
                        <FormField className="thematized" type="email" id="email" value={email} label={t("Email")} placeholder={t("Ton email")} onChange={setEmail} />
                        <FormField className="thematized" type="text" id="username" value={username} label={t("Pseudo")} placeholder={t("Ton pseudo")} onChange={setUsername} />
                        <FormField className="thematized" type="password" id="password" value={registerPassword} label={t("Mot de passe")} placeholder={t("Ton mot de passe")} onChange={setRegisterPassword} />
                        <FormField className="thematized" type="password" id="passwordConfirm" value={passwordConfirmation} label={t("Confirmation mot de passe")} placeholder={t("Confirmation de ton mot de passe")} onChange={setPasswordConfirmation} />

                        <p className="legals">{t("En t'inscrivant, tu acceptes les")} <Link to="/CGV">{t("Conditions Générales de Vente")}</Link>.</p>

                        {(registerErrors && registerErrors.length > 0) ? <ul className="formError">{registerErrors.map((error, index) => <li key={index}>{error}</li>)}</ul> : null}

                        <button className="btn thematized" type="submit">{t("S'inscrire")}</button>
                    </div>
                    <div className="leftPart">
                        <img src="./images/register_player.png" alt="register illustration" />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Register
