import React from "react";

const StatusPill = ({ status }) => {
	const statusMap = {
		active: { color: "darkgreen", label: "Actif" },
		inactive: { color: "grey", label: "Inactif" },
		deleted: { color: "darkred", label: "Supprimé" },
		paused: { color: "darkgoldenrod", label: "En pause" },
		unpaid: { color: "darkorange", label: "Non payé" },
		trialing: { color: "darkslateblue", label: "Période d'essai" },
	};

	const { color, label } = statusMap[status] || { color: "grey", label: "Inconnu" };

	const pillStyle = {
		backgroundColor: color,
		color: "white",
		padding: "5px 10px",
		borderRadius: "12px",
		textAlign: "center",
		minWidth: "80px",
	};

	return <span style={pillStyle}>{label}</span>;
};

export default StatusPill;
